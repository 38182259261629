import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import _ from 'lodash';
import Selectors from '../Selectors';
import ActionCreator from '../ActionCreator';
import * as FormUtil from '../Utils/FormUtil';
import * as L from '../Utils/Lang';
import CVSSelector from '../Components/CVSSelector';
import * as Widget from '../Components/Widget';
import * as Icon from '../Components/Icon';
import { AIRPORT_PICKUP_TIME } from '../Utils/AirportDataUtil';
import AppConfig from '../../src/Config';

const DELIVERY_LOCATION = {
  CONVENIENCE_STORE: 'convenience_store',
  AIRPORT: 'airport',
};

class CheckoutForm extends React.Component {
  constructor(props) {
    super(props);
    let { profile, cartData } = this.props;
    const defaultValues = {
      receiver: cartData.receiver || (profile ? profile.name : ''),
      email: cartData.email || (profile ? profile.email : ''),
      address: cartData.address || (profile ? profile.address : ''),
      phone: cartData.phone || (profile ? profile.phone : ''),
      taxIDNumber: cartData.taxIDNumber || '',
      payment_type: cartData.payment_type || 'ecpay',
      update_profile: cartData.update_profile || false,
      store_id: cartData.store_id,
      store_name: cartData.store_name,
      delivery_location_type:
        cartData.delivery_location_type || DELIVERY_LOCATION.CONVENIENCE_STORE,
    };


    this.state = {
      values: defaultValues,
      submitting: false,
      errMsg: '',
      showHint: false,
      readTheHint: false,
    };
  }

  componentWillMount() {
    let { appActions } = this.props;
    let { values } = this.state;
    appActions.cart.setData(values, true);
  }

  componentDidMount() {
    let {cartData} = this.props;
    const hasStorePickup =
      (AppConfig.favor === 'tel25-sp' &&
        this._hasAnySpFirstBuy(cartData)) ||
      AppConfig.favor === 'tel25';
    if (!hasStorePickup) {
      this._simInputUpdateWithValue('payment_type')('ecpay')();
    }
  }

  componentDidUpdate(prevProps) {
    let {cartData} = this.props;
    const hasStorePickupBefore =
      (AppConfig.favor === 'tel25-sp' &&
        this._hasAnySpFirstBuy(prevProps.cartData)) ||
      AppConfig.favor === 'tel25';
    const hasStorePickupNow =
      (AppConfig.favor === 'tel25-sp' &&
        this._hasAnySpFirstBuy(cartData)) ||
      AppConfig.favor === 'tel25';

    if (hasStorePickupBefore && !hasStorePickupNow) {
      this._simInputUpdateWithValue('payment_type')('ecpay')();
    }
  }

  render() {
    let { extraCss = '', navActions, cartData } = this.props;
    let { values, submitting, errMsg, showHint, readTheHint } = this.state;

    const hasStorePickup =
      (AppConfig.favor === 'tel25-sp' && this._hasAnySpFirstBuy(cartData)) ||
      AppConfig.favor === 'tel25';

    return (
      <Wrapper extraCss={extraCss}>
        <Card spStyle={AppConfig.favor === 'tel25-sp'}>
          <div className="content">
            <h3>{L.s(`recipient`)}</h3>
            <div className="red-hint">*為必填欄位</div>
            <div className="field">
              <label>
                {L.s(`name`)}
                <label style={{ color: 'red' }}>*</label>
              </label>

              <div>
                <Widget.SimpleInput
                  placeholder={'ex: 陳小明'}
                  value={values.receiver}
                  onChange={this._onInputUpdate('receiver')}
                />
              </div>
            </div>

            <div className="field">
              <label>
                {L.s(`phone`)}
                <label style={{ color: 'red' }}>*</label>
              </label>

              <div>
                <Widget.SimpleInput
                  placeholder={'ex: 0912345678'}
                  value={values.phone}
                  onChange={this._onInputUpdate('phone')}
                />
              </div>
            </div>

            <div className="field">
              <label>
                {L.s(`email`)}
                <label style={{ color: 'red' }}>*</label>
              </label>

              <div>
                <Widget.SimpleInput
                placeholder={'ex: star123@gmail.com'}
                  value={values.email}
                  onChange={this._onInputUpdate('email')}
                />
              </div>
            </div>

            <div className="field">
              <label>
                統一編號
              </label>

              <div>
                <Widget.SimpleInput
                  placeholder={'ex: 12345678'}
                  value={values.taxIDNumber}
                  onChange={this._onInputUpdate('taxIDNumber')}
                />
              </div>
            </div>
            <div className="hint">
              *提醒您，若有填寫統編，將開立上方填入統一編號之電子發票，請確定輸入為正確統編
            </div>
            <div className="hint">
              *提醒您，我們將會於取貨後10個工作天內寄送電子發票至您所填寫的E-Mail，請務必確認此電子信箱可以正常收發信件
            </div>
            {/* <div className="field">
              <label>{L.s(`address`)}</label>

              <div>
                <Widget.SimpleInput
                  value={values.address}
                  onChange={this._onInputUpdate('address')}
                />
              </div>
            </div> */}

            {AppConfig.favor === 'tel25' && (
              <Widget.Checkbox
                style={{ marginBottom: 30 }}
                iconStyle={{ fill: '#3f8dcf' }}
                labelStyle={{ color: '#3f8dcf' }}
                checked={values.update_profile}
                label={L.s(`remember-my-info`)}
                onCheck={() =>
                  this.setState({
                    values: {
                      ...values,
                      update_profile: !values.update_profile,
                    },
                  })
                }
              />
            )}

            <h3>{L.s(`payment`)}</h3>

            {/* <div className="field">
              <label>{L.s(`method`)}</label>

              <div>
                <Widget.SimpleSelect
                  value={values.payment_type}
                  onChange={this._onInputUpdate('payment_type')}
                >
                  <option value="ecpay">{`${L.s(`credit-card-online`)}/${L.s(
                    'cv-pay'
                  )}`}</option>
                  <option value="ecpay_cvs">{L.s(`store-pickup`)}</option> */}
            {/*<option value='paypal'>Paypal</option>*/}
            {/*<option value='offline'>匯款</option>*/}
            {/* </Widget.SimpleSelect>
              </div>
            </div> */}

            <div
              className="field"
              style={{
                justifyContent: 'space-around',
                alignItems: 'flex-start',
                flexWrap: 'wrap',
              }}
            >
              <div style={{ width: '100%', marginBottom: 10 }}>
                {L.s(`method`)}
              </div>

              <div>
                <Widget.CheckboxField>
                  <div
                    onClick={this._simInputUpdateWithValue('payment_type')(
                      'ecpay'
                    )}
                  >
                    {values.payment_type === 'ecpay' ? (
                      <Icon.RadioButtonChecked color="#3f8dcf" />
                    ) : (
                        <Icon.RadioButtonUnchecked color="#3f8dcf" />
                      )}
                  </div>
                  <div className="label-text">{`${L.s(
                    'credit-card-online'
                  )}`}</div>
                  <div className="icons">
                    <img
                      className="credit-card"
                      src="/images/sp-icon/credit-card-icon01.png"
                    />
                    <img
                      className="credit-card"
                      src="/images/sp-icon/credit-card-icon02.png"
                    />
                    <img
                      className="credit-card"
                      src="/images/sp-icon/credit-card-icon03.png"
                    />
                    <img
                      className="credit-card"
                      src="/images/sp-icon/credit-card-icon04.png"
                    />
                  </div>
                </Widget.CheckboxField>

                {AppConfig.favor === 'tel25-sp' && (
                  <Widget.CheckboxField>
                    <div
                      onClick={this._simInputUpdateWithValue('payment_type')(
                        'ecpay_cvpay'
                      )}
                    >
                      {values.payment_type === 'ecpay_cvpay' ? (
                        <Icon.RadioButtonChecked color="#3f8dcf" />
                      ) : (
                          <Icon.RadioButtonUnchecked color="#3f8dcf" />
                        )}
                    </div>
                    <div className="label-text">{`${L.s('cv-pay')}`}</div>
                    <div className="icons">
                      <img
                        className="cvs"
                        src="/images/sp-icon/cvs-icon01.png"
                      />
                      <img
                        className="cvs"
                        src="/images/sp-icon/cvs-icon02.png"
                      />
                      <img
                        className="cvs"
                        src="/images/sp-icon/cvs-icon03.png"
                      />
                      <img
                        className="cvs"
                        src="/images/sp-icon/cvs-icon04.png"
                      />
                    </div>
                  </Widget.CheckboxField>
                )}

                {hasStorePickup && (
                  <Widget.CheckboxField>
                    <div
                      onClick={this._simInputUpdateWithValue('payment_type')(
                        'ecpay_cvs'
                      )}
                    >
                      {values.payment_type === 'ecpay_cvs' ? (
                        <Icon.RadioButtonChecked color="#3f8dcf" />
                      ) : (
                          <Icon.RadioButtonUnchecked color="#3f8dcf" />
                        )}
                    </div>
                    <div className="label-text">{`${L.s('store-pickup')}`}</div>
                    <div className="single-icon-container">
                      <img
                        className="cvs"
                        src="/images/sp-icon/cvs-icon01.png"
                      />
                    </div>
                  </Widget.CheckboxField>
                )}
              </div>
            </div>
            {!(AppConfig.favor === 'tel25-sp') && (
              <>
                <div className="hint">
                  *{L.s('payment-delivery-location-note')}
                </div>
                <div className="hint">
                  *{L.s('only-open-online-credit-card')}
                </div>
              </>
            )}

            {hasStorePickup && (
              <Fragment>
                <h3>{L.s('choose-delivery-location')}</h3>
                {// pick up at airport must be paid online, tel25-sp do not support airport-pickup
                  AppConfig.favor === 'tel25-sp' ? (
                    <div className="field">
                      <label>{L.s('location')}</label>
                      <div style={{ textAlign: 'center' }}>
                        {L.s('delivery-location-convenience_store-sp')}
                      </div>
                    </div>
                  ) : (
                      <div
                        className="field"
                        style={{
                          alignItems: 'flex-start',
                          justifyContent: 'space-between',
                        }}
                      >
                        <label>{L.s('location')}</label>
                        <Widget.Row>
                          <Widget.CheckboxField>
                            <div
                              onClick={this._simInputUpdateWithValue(
                                'delivery_location_type'
                              )(DELIVERY_LOCATION.CONVENIENCE_STORE)}
                            >
                              {values.delivery_location_type ===
                                DELIVERY_LOCATION.CONVENIENCE_STORE ? (
                                  <Icon.RadioButtonChecked color="#3f8dcf" />
                                ) : (
                                  <Icon.RadioButtonUnchecked color="#3f8dcf" />
                                )}
                            </div>
                            <div className="label-text">
                              {L.s('delivery-location-convenience_store')}
                            </div>
                          </Widget.CheckboxField>

                          {
                            values.payment_type !== 'ecpay_cvs' && (
                              <Widget.CheckboxField>
                                <div
                                  onClick={this._simInputUpdateWithValue(
                                    'delivery_location_type'
                                  )(DELIVERY_LOCATION.AIRPORT)}
                                >
                                  {values.delivery_location_type ===
                                  DELIVERY_LOCATION.AIRPORT ? (
                                      <Icon.RadioButtonChecked color="#3f8dcf" />
                                    ) : (
                                      <Icon.RadioButtonUnchecked color="#3f8dcf" />
                                    )}
                                </div>
                                <div className="label-text">
                                  {L.s('delivery-location-airport')}
                                </div>
                              </Widget.CheckboxField>
                            )
                          }
                        </Widget.Row>
                      </div>
                    )}

                {/* select delivery_location detail */}
                <div className="field">
                  {values.delivery_location_type ===
                    DELIVERY_LOCATION.CONVENIENCE_STORE && (
                      <CVSSelector
                        store_id={values.store_id}
                        store_name={values.store_name}
                        onSelect={this._onCVSSelect}
                      />
                    )}

                  {values.delivery_location_type ===
                    DELIVERY_LOCATION.AIRPORT && (
                      <div className="field">
                        <div style={{ width: '100%', marginBottom: 10 }}>
                          {L.s('choose-airport')}
                        </div>

                        <Widget.CheckboxField>
                          <div
                            value="airport-TSA"
                            onClick={() =>
                              this._onDeliveryLocationSelect('airport-TSA')
                            }
                          >
                            {values.store_name === '台北松山-統振櫃檯' ? (
                              <Icon.RadioButtonChecked color="#3f8dcf" />
                            ) : (
                                <Icon.RadioButtonUnchecked color="#3f8dcf" />
                              )}
                          </div>
                          <div className="airport-label-text">
                            {L.s('airport-TSA')} ({L.s('pickup-time')}:
                          {AIRPORT_PICKUP_TIME['airport-TSA']})
                        </div>
                        </Widget.CheckboxField>

                        <Widget.CheckboxField>
                          <div
                            value="airport-TPE-T1"
                            onClick={() =>
                              this._onDeliveryLocationSelect('airport-TPE-T1')
                            }
                          >
                            {values.store_name === '桃園一航-多圓發櫃檯' ? (
                              <Icon.RadioButtonChecked color="#3f8dcf" />
                            ) : (
                                <Icon.RadioButtonUnchecked color="#3f8dcf" />
                              )}
                          </div>
                          <div className="airport-label-text">
                            {L.s('airport-TPE-T1')} ({L.s('pickup-time')}:
                          {AIRPORT_PICKUP_TIME['airport-TPE-T1']})
                        </div>
                        </Widget.CheckboxField>

                        <Widget.CheckboxField>
                          <div
                            value="airport-TPE-T2"
                            onClick={() =>
                              this._onDeliveryLocationSelect('airport-TPE-T2')
                            }
                          >
                            {values.store_name === '桃園二航-多圓發櫃檯' ? (
                              <Icon.RadioButtonChecked color="#3f8dcf" />
                            ) : (
                                <Icon.RadioButtonUnchecked color="#3f8dcf" />
                              )}
                          </div>
                          <div className="airport-label-text">
                            {L.s('airport-TPE-T2')} ({L.s('pickup-time')}:
                          {AIRPORT_PICKUP_TIME['airport-TPE-T2']})
                        </div>
                        </Widget.CheckboxField>

                        <Widget.CheckboxField>
                          <div
                            value="airport-KHH"
                            onClick={() =>
                              this._onDeliveryLocationSelect('airport-KHH')
                            }
                          >
                            {values.store_name === '高雄小港-統振櫃檯' ? (
                              <Icon.RadioButtonChecked color="#3f8dcf" />
                            ) : (
                                <Icon.RadioButtonUnchecked color="#3f8dcf" />
                              )}
                          </div>
                          <div className="airport-label-text">
                            {L.s('airport-KHH')} ({L.s('pickup-time')}:
                          {AIRPORT_PICKUP_TIME['airport-KHH']})
                        </div>
                        </Widget.CheckboxField>
                      </div>
                    )}
                </div>

                {values.delivery_location_type === DELIVERY_LOCATION.AIRPORT &&
                  values.store_id && (
                    <a
                      style={{ color: '#3a8bd1' }}
                      href={`/images/airport-store-location/${
                        values.store_id
                        }.jpg`}
                      target="_blank"
                    >
                      {L.s(`view-store-location`)} ({L.s(values.store_id)})
                    </a>
                  )}

                {values.delivery_location_type ===
                  DELIVERY_LOCATION.CONVENIENCE_STORE && (
                    <div className="hint">*{L.s('shipping-fee-notice')}</div>
                  )}
              </Fragment>
            )}
          </div>

          <OrderInfo spStyle={AppConfig.favor === 'tel25-sp'}>
            <div className="orderInfoItem">
              <div>{L.s('products-total')}</div>
              <div className="price">{`NT$${cartData.price -
                cartData.shipping_fee}`}</div>
            </div>
            <div className="orderInfoItem">
              <div>{L.s('shipping')}</div>
              <div className="price">{`NT$${cartData.shipping_fee}`}</div>
            </div>
            <div className="separator" />
            <div className="orderInfoItem total">
              <div>{L.s(`total`)}</div>
              <div className="price">{`NT$${cartData.price}`}</div>
            </div>
          </OrderInfo>
        </Card>

        {/*values.payment_type === 'ecpay_cvs' ? (
          <div style={{marginBottom: 10}}>
            <CVSSelector
              store_id={values.store_id}
              store_name={values.store_name}
              onSelect={this._onCVSSelect}
            />
          </div>
        ) : (
          <div>
            <h3 style={{ marginBottom: 10 }}>寄送地址</h3>
            <div style={{height: 10}}/>
            <CheckoutForm.Field>
                <textarea
                  value={values.address}
                  onChange={this._onInputUpdate('address')}
                />
              </CheckoutForm.Field>
          </div>
        )*/}

        {errMsg && (
          <Widget.Row extraCss="margin-bottom: 5px; align-self: flex-end; color: red; font-size: 13px;">
            {errMsg}
          </Widget.Row>
        )}

        <ButtonBar>
          {submitting ? (
            <Widget.Col extraCss="align-items: flex-end;">
              <Widget.Spinner />
              <p style={{color: 'lightblue', marginTop: 5}}>處理中，請勿離開畫面</p>
            </Widget.Col>
          ) : (
            !showHint &&
              <Widget.BlueButton
                onClick={this._hasAnySpDeposit(cartData) ? (
                  () => this.setState({showHint: true}) 
                ):(
                  this._checkout
                )}
                disabled={!this._isAllowNextStep()}
              >
                {L.s(`confirm-order`)}
              </Widget.BlueButton>
            )}
        </ButtonBar>
        {
          showHint &&
          <Card css={"padding: 20px 20px 20px 20px;"}>
            <div className="content"> 
              <div className="grey-hint">
                提醒您，請留意您目前剩餘的使用天數及用量，繼續付款步驟後，將會由新的加購方案覆蓋舊的方案，謝謝。
              </div>
              <div className="got-it">
              { 
                readTheHint ?
                <Icon.RadioButtonChecked color="#5f5e5e"
                  onClick = {() => this.setState({readTheHint: false})}
                />
                :
                <Icon.RadioButtonUnchecked color="#5f5e5e"
                  onClick = {() => this.setState({readTheHint: true})}
                />
              }
              
                <p>我知道了</p>
              </div>
            </div>
            <ButtonBar>
              <Widget.BlueButton
                onClick={this._checkout}
                disabled={this.state.readTheHint===false}
              >
                {L.s(`confirm-order`)}
              </Widget.BlueButton>
            </ButtonBar> 
          </Card>
        }
      </Wrapper>
    );
  }

  _hasAnySpFirstBuy = cartData => {
    let hasAnySpFirstBuy =
    !!_.reduce(cartData.items, (result, value, key) =>
      [...result, ...value.configs]
      , [])
      .find((item) => item.is_first_buy)
    return hasAnySpFirstBuy
  }

  _hasAnySpDeposit = cartData => {
    let hasAnySpDeposit =
    AppConfig.favor === 'tel25-sp' && 
    !!_.reduce(cartData.items, (result, value, key) =>
      [...result, ...value.configs]
      , [])
      .find((item) => !item.is_first_buy)
    return hasAnySpDeposit
  }

  _isAllowNextStep = () => {
    let { values } = this.state;
    let { disableNext, cartData } = this.props;
    const hasStorePickup =
      (AppConfig.favor === 'tel25-sp' && this._hasAnySpFirstBuy(cartData)) ||
      AppConfig.favor === 'tel25';

    if (!hasStorePickup) {
      if (
        !values.receiver ||
        !values.email ||
        !values.phone ||
        !values.payment_type
      ) {
        return false;
      }
    } else {
      if (
        !values.receiver ||
        !values.email ||
        !values.phone ||
        !values.payment_type ||
        !values.delivery_location_type ||
        !values.store_id ||
        !values.store_name
      ) {
        return false;
      }
    }

    if (disableNext) {
      return false;
    }

    return true;
  };

  _simInputUpdateWithValue = key => value => () => {
    return this._onInputUpdate(key)({
      target: {
        value,
      },
    });
  };

  _onInputUpdate = key => e => {
    let { values } = this.state;
    let { appActions } = this.props;
    let resetValues = {};
    if (key === 'delivery_location_type') {
      resetValues = { store_id: '', store_name: '' };
      // should update pre-checkout data, for shipping fee
      appActions.cart.setData(
        { ...values, delivery_location_type: e.target.value },
        true
      );
    }
    if (key === 'payment_type') {
      resetValues = {
        delivery_location_type: DELIVERY_LOCATION.CONVENIENCE_STORE,
        store_id: '',
        store_name: '',
      };
    }
    this.setState({
      values: {
        ...values,
        ...resetValues,
        [key]: e.target.value,
      },
    });
  };

  _checkout = () => {
    let { values } = this.state;
    let { appActions, navActions, cartData, setCleaningStatus } = this.props;
    if ((AppConfig.favor === 'tel25-sp') && (
        Object.keys(cartData.items).some(
          k => cartData.items[k]['configs'][0].iccid === ''
        )
      )) {
      this.setState({ errMsg: L.s('deposit-product-enter-hint') });
    } else if (!FormUtil.validateTaxId(values.taxIDNumber)) {
      this.setState({ errMsg: L.s('form-error-tax-id') });
    } else if (!FormUtil.validateEmail(values.email)) {
      this.setState({ errMsg: L.s('form-error-email') });
    } else if (!FormUtil.validateReceiverName(values.receiver)) {
      this.setState({
        errMsg: L.s('form-error-name'),
      });
    } else if (!FormUtil.validateMobileNumber(values.phone)) {
      this.setState({
        errMsg: L.s('form-error-phone'),
      });
    } else { // Success
      this.setState({ errMsg: '', submitting: true });
      setCleaningStatus(true)

      if (values.update_profile) {
        let data = { name: values.receiver, email: values.email, phone: values.phone }

        appActions.editMyProfile(data)
          .catch(err => console.warn(err))
      }

      appActions.cart.checkout(values)
        .then(order => {
          this.setState({ submitting: false });
          navActions.push(`/order?id=${order.id}`);
        })
        .catch(err =>
          setCleaningStatus(false)
        );
    }
  };

  _onDeliveryLocationSelect = value => {
    let { values } = this.state;
    const AIRPORT_NAME_MAP = {
      'airport-TSA': '台北松山-統振櫃檯',
      'airport-TPE-T1': '桃園一航-多圓發櫃檯',
      'airport-TPE-T2': '桃園二航-多圓發櫃檯',
      'airport-KHH': '高雄小港-統振櫃檯',
    };
    this.setState({
      values: {
        ...values,
        store_id: value,
        store_name: AIRPORT_NAME_MAP[value] || value,
      },
    });
  };

  _onCVSSelect = form => {
    let { values } = this.state;
    let { appActions } = this.props;
    appActions.cart.setData(values, false).then(() => {
      // window.location = `/cvs?store_id=${Math.floor(Math.random()*100)}&store_name=STORE_${Math.floor(Math.random()*100)}`
      form.submit();
    });
  };
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${props => props.extraCss};
`;

const Card = styled.div`
  margin-bottom: 30px;
  border-radius: 10px;
  padding: 30px 20px 40px;
  background-color: ${props => (props.spStyle ? '#393838' : '#ffffff')};
  box-shadow: 0 1px 10px 0 #dfdfdf;

  & > .content {
    max-width: 384px;
    margin: 0 auto;
    color: ${props => (props.spStyle ? '#dbdbdb' : '#343434')};
    font-size: 16px;

    & > .hint {
      color: grey;
      font-size: 14px;
      margin-bottom: 30px;
    }

    & > .red-hint {
      color: red;
      font-size: 14px;
      margin-bottom: 30px;
      text-align: center;
    }

    & > .grey-hint {
      color: #607d8b;
      font-size: 14px;
      text-align: center; 
    }

    & > .got-it {
      display: flex;
      flex-direction: row;
      justify-content: center;
      font-size: 14px;
      margin-top: 20px;
      text-align: center;

      & > p {
        font-size: 14px;
        color: #5f5e5e;
      } 
    }

    & > h3 {
      margin-bottom: 30px;
      font-weight: 600;
      text-align: center;
    }

    & > .field {
      white-space: pre-wrap;
      margin-bottom: 30px;
      display: flex;
      align-items: center;

      & > label {
        width: 75px;
        margin-right: 20px;
      }

      & > div {
        flex-grow: 1;
      }
    }
  }
  ${props => props.css};
`;

const OrderInfo = styled.div`
  width: 100%;
  margin-top: 10px;
  color: ${props => (props.spStyle ? '#dbdbdb' : '#343434')};
  padding-top: 10px;
  border-top: 5px solid #e2e2e2;
  font-size: 16px;
  line-height: 30px;

  & > .orderInfoItem {
    display: flex;
    justify-content: space-between;
  }

  & > .total {
    font-weight: 600;
  }

  & > .separator {
    height: 1px;
    margin: 10px 0;
    background: #e5e5e5;
  }

  &.line-through > .orderInfoItem > .price {
    text-decoration: line-through;
  }
`;

const ButtonBar = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export default connect(
  (state, ownProps) => ({
    profile: Selectors.getLoginUser(state),
    cartData: Selectors.cart.getData(state),
  }),
  ActionCreator
)(CheckoutForm)
